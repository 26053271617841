/* You can add global styles to this file, and also import other style files */
@font-face {
  font-family: "Gallagher Slab";
  src: url(/assets/fonts/gallagherslab-light.woff2) format("woff2"),url(/assets/fonts/gallagherslab-light.woff) format("woff"),url(/assets/fonts/gallagherslab-light.otf) format("opentype");
  font-style: normal;
}

@font-face {
  font-family: "Gallagher Slab Medium";
  src: url(/assets/fonts/gallagherslab-medium.woff2) format("woff2"),url(/assets/fonts/gallagherslab-medium.woff) format("woff"),url(/assets/fonts/gallagherslab-medium.otf) format("opentype");
  font-style: normal;
}
